module.exports = [{
      plugin: require('/Users/nickchan/workspace/arw_brand_website/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-101088202-1"],"pluginConfig":{"head":true,"exclude":[]}},
    },{
      plugin: require('/Users/nickchan/workspace/arw_brand_website/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"vjz0bcn"},"google":{"families":["Noto Sans TC","Noto Sans SC"]}},
    },{
      plugin: require('/Users/nickchan/workspace/arw_brand_website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
