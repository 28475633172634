/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './node_modules/bootstrap/dist/css/bootstrap.min.css'
import './node_modules/@fortawesome/fontawesome-svg-core/styles.css'
import './node_modules/leaflet/dist/leaflet.css'

import './src/components/common.css'
import './src/components/top-nav/menu.css'
import './src/components/common-lang.css'
